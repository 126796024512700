<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 80%" @submit.prevent="save">
        <b-row>
          <b-col cols="12">
            <b-form-group label=" " label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Description" rules="required">
                <QuillEditor
                  :editorRef="'descriptionEditor'"
                  v-model="addForm.description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="">
            <div class="border rounded p-2" style="margin: 0px">
              <h4 class="mb-1">Header صورة</h4>
              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="`https://ar.lpcentre.com/${addForm.header_img}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="removeHeaderImage"
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1"> </b-card-text>
                  <div class="d-inline-block mt-1">
                    <b-form-group label-for="blog-edit-title" class="mb-2">
                      <validation-provider #default="{ errors }" name="header Image ">
                        <b-form-file
                          ref="refInputEl"
                          v-model="addForm.header_img"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="نص بديل للصورة" label-for="blog-edit-title" class="mb-2">
                      <b-form-input id="alt-image" v-model="addForm.alt_header_img" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="4">
            <b-form-group label=" الكلمات المفتاحية" label-for="blog-content" class="mb-2">
              <b-form-tags v-model="addForm.keywords" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label=" العنوان الاضافي " label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Title" rules="required">
                <b-form-input id="blog-edit-title" v-model="addForm.meta_title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label=" الوصف الاضافي " label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Description" rules="required">
                <b-form-input id="blog-edit-slug" v-model="addForm.meta_description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import QuillEditor from "@core/components/editor/Editor";
import { ref, reactive } from "@vue/composition-api";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import store from '@/store'
import Vue from "vue";

export default {
  setup(props) {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const Tabimage = ref("media/svg/files/blank-image.svg");

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      refPreviewEl.value.src = base64;
    });

    const Form = ref({
      description: '',
      meta_title: "",
      meta_description: "",
      keywords: "",
      header_img: "",
      alt_header_img: "",
    });
    const addForm = reactive(Form);

    store.dispatch('setting/getPrivacyPolice')
      .then(response => {
        if (response?.data.data) {
          Form.value = response?.data.data;
        }
      });

    const addFormalidate = ref();

    const save = () => {
      const formData = new FormData();
      formData.append("description", addForm.description);
      formData.append("meta_title", addForm.meta_title);
      formData.append("meta_description", addForm.meta_description);
      formData.append("header_img", addForm.header_img);
      formData.append("alt_header_img", addForm.alt_header_img);
      formData.append("keywords", addForm.keywords);

      store.dispatch('setting/updatePrivacyPolice', formData)
        .then(response => {
          Vue.swal({
            icon: 'success',
            title: 'تم تحديث!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          });
        });
    };

    const removeHeaderImage = () => {
      refPreviewEl.value.src = Tabimage;
      addForm.header_img = '';
    };

    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr, formData;
      let token = localStorage.getItem('token');
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', 'https://lpcentre.com/api/upload_image_tiny_mce');

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100);
      };
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      xhr.onload = function () {
        let json;
        if (xhr.status === 403) {
          failure('HTTP Error: ' + xhr.status, { remove: true });
          return;
        }
        if (xhr.status < 200 || xhr.status >= 300) {
          failure('HTTP Error: ' + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json || typeof json.location != 'string') {
          failure('Invalid JSON: ' + xhr.responseText);
          return;
        }
        success(json.location);
      };
      xhr.onerror = function () {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
      };
      formData = new FormData();
      formData.append('image', blobInfo.blob(), blobInfo.filename());
      xhr.send(formData);
    }

    return {
      example_image_upload_handler,
      addForm,
      refInputEl,
      refPreviewEl,
      addFormalidate,
      Tabimage,
      inputImageRenderer,
      Form,
      save,
      removeHeaderImage,
      required,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    QuillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
